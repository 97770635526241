import { render, staticRenderFns } from "./PartnerList.vue?vue&type=template&id=ccf1cdee&scoped=true&"
import script from "./PartnerList.vue?vue&type=script&lang=js&"
export * from "./PartnerList.vue?vue&type=script&lang=js&"
import style0 from "./PartnerList.vue?vue&type=style&index=0&id=ccf1cdee&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ccf1cdee",
  null
  
)

export default component.exports